/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.vs {
    height: 100%;
    overflow: auto;
    padding-right: 5px;
}


.hidden {
    visibility: hidden;
}


.pad5 {
    padding: 5px;
}

.pad10 {
    padding: 10px;
}

.pad15 {
    padding: 15px;
}

.pad20 {
    padding: 20px;
}

.sm-show {
    display: none;
}


.hide {
    display: none;
}



.w100 {
    width: 100%;
}

.w80 {
    width: 80%;
}

.w75 {
    width: 75%;
}

.w66 {
    width: 66%;
}

.w50 {
    width: 50%;
}
.w40 {
    width: 40%;
}

.w33 {
    width: 33%;
}
.w30 {
    width: 30%;
}

.w25 {
    width: 25%;
}

.w20 {
    width: 20%;
}

.w10 {
    width: 10%;
}

.h100 {
    height: 100%;
}

.r10 {
    border-radius: 10px;
}

.r20 {
    border-radius: 20px;
}

.r30 {
    border-radius: 30px;
}

.p5 {
    padding: 5px;
}

.p10 {
    padding: 10px;
}

.p15 {
    padding: 15px;
}

.p20 {
    padding: 20px;
}
.p30 {
    padding: 30px;
}
.p40 {
    padding: 40px;
}
.p50 {
    padding: 50px;
}

.m5 {
    margin: 5px;
}
.m0 {
    margin: 0px;
}

.m10 {margin: 10px;}

.relative {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.h100-segment {
    height: calc(100% - 55px);
}

.sm-h100-segment {
    height: 100%;
}


.el-center {
    margin: 0 auto;
}
.img-center {
    display: block;
    margin: 0 auto;
}

.txt-uppercase {
    text-transform: uppercase;
}

.txt-left {
    text-align: left;
}

.txt-center {
    text-align: center;
}

.txt-right {
    text-align: right;
}

.txt-primary {
    color: #007cdc;
}

.txt-danger {
    color: #eb445a;
}

.txt-warning {
    color: #e6b219;
}

.txt-white {
    color: #ffffff;
}

.txt-white-bg {
    color: #ffffff;
}

.txt-grey {
    color: #555555;
}

.txt-dark {
    color: #000;
}

.txt-strike {
    text-decoration: line-through;
}

.txt-large {
    font-size: 25px;
}
.txt-medium {
    font-size: 18px;
}

.txt-small {
    font-size: 12px;
}

.txt-bold {
    font-weight: bold;
}

.txt-light {
    font-weight: 300;
}

.no-dding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.no-border {
    border: none;
}



.panel {
    border: 1px solid #d0d0d0;
    padding: 8px;
    margin-bottom: 10px;
    //background-color: #efefef
    border-radius: 20px;
    white-space: pre-line;

}

.spaced {
    margin-bottom: 20px;
}



.selected {
    --background: #007cdc !important;
    background: #007cdc !important;
    color: #fff;
}

.selected:hover {
    --background: #0061af;
    background: #0061af;
    color: #fff;
}

.selected ion-icon {
    color: #fff;
}


.panel {
    border: 1px solid #d0d0d0;
    padding: 8px;
    margin-bottom: 10px;
    //background-color: #efefef
}

.panel-spaced {
    border: 1px solid #d0d0d0;
    padding: 8px;
    margin-bottom: 40px;
}

.panel-dashed {
    border: 1px dashed #d0d0d0;
    padding: 8px;
    margin-bottom: 10px;
}

.panel-primary {
    background-color: #337ab7;
    color: #fff;
    padding: 5px;
}

.panel-light {
    background-color: #fff;
    padding: 15px;
}

.panel-float-bottom {
    position: absolute;
    z-index: 9;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #333;
    color: #fff;
}

.panel-fh-overlay-hide {
    position: absolute;
    background-color: #fff;
    z-index: 15;
    display: none;
    height: 100%;
    border-right: 2px solid #333;
}

.panel-form-row-top {
    background-color: #666;
}

.panel-form-row {
    background-color: #efefef;
    padding: 20px;
    border: 1px dashed #666;
    border-top: none;
}

.panel-form-col-top {
    background-color: #dcdcdc;
    border: 1px solid #666;
    border-bottom: none;
}

.panel-form-col {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #666;
    border-top: none;
}

.panel-form-obj-top {
    background-color: #fff;
    border: 1px solid #999;
    border-bottom: none;
}

.panel-form-obj {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #999;
    border-top: none;
}

.markinput {
    background-color: #efefef;
    margin-top: 7px;
    //text-align: center;
}

.inputlist {
    //background: transparent;
    border: 0px;
    padding: 3px 0px 7px;
    margin-top: 10px;
}

textarea {
    background-color: #efefef !important;
    color: #222 !important;
}



.screen-shade {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}


.closeModal {
    border-left: 1px solid #555;
    border-bottom: 1px solid #555;
    font-size: 18px;
    width: 60px;
    height: 45px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 120;
    border-radius: 0 0px 0 30px;
    text-align: center;
    line-height: 50px;
    background-color: #555;
    color: #fff;
    cursor: pointer;
}

.closeModal:hover {
    background-color: #333;
}

#modal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#modal .modalContent {
    top: 2.5%;
    left: 10%;
    right: 10%;
    bottom: 3%;
    position: absolute;
    z-index: 101;
    padding: 20px;
    padding-top: 50px;
    overflow: auto;
    border-radius: 20px;
    border: 1px solid #555;
    color: #000;
    background: #fff;
}



#miniModal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 105;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#miniModal .modalContent {
    top: 25%;
    left: 20%;
    right: 20%;
    bottom: 25%;
    position: absolute;
    z-index: 110;
    padding: 20px;
    overflow: auto;
    border-radius: 20px;
    border: 1px solid #555;
    background: #fff;
}

#microModal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 125;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#microModal .modalContent {
    top: 30%;
    left: 30%;
    right: 30%;
    bottom: 30%;
    position: absolute;
    z-index: 130;
    padding: 20px;
    overflow: auto;
    border-radius: 20px;
    border: 1px solid #555;
    background: #fff;
}



.tbl-striped tr:nth-child(even) {
    background-color: #efefef;
}

.tbl-striped tr:hover {
    background-color: #dcdcdc !important;
}


.collapse-companion {
    height: 100%;
    overflow: auto;
    background: #fff;
}

.section-fh-border {
    overflow: auto;
    position: relative;
    border-right: 1px solid #efefef;
    height: 100%;
}

@media screen and (max-width: 767px) {
    .collapse {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .collapse-companion {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 5px;
    }

}